import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const Membership = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        phoneNumber: '',
        emailAddress: '',
        faxAddress: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://server.pellenilsen.no/membership', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const data = await response.json();

            emailjs.send(
                'service_ldmf0kk',
                'template_ug8x7lx',
                {
                    from_name: formData.fullName,
                    from_phone: formData.phoneNumber,
                    from_email: formData.emailAddress,
                    from_adress: formData.faxAddress,
                    to_name: 'BraNok Møteplass',
                    to_email: 'branokrebell@gmail.com'
                },
                'MJkVuZNwoJrulpmEF'
            )
            alert('Du er nå ett medlem.');
        } catch (error) {
            console.error('Det oppstå ett problem med påmeldingen.');
        }
    };

    return (
        <div className='bg-light-shades p-6 md:p-12'>
            <section className='mb-12'>
                <h1 className='text-4xl text-main-brand font-extrabold mb-4'>Medlemskap</h1>
                <p className='text-lg text-dark-shades mb-4'>
                    Å bli medlem på BraNok Møteplass kommer en rekke fordeler, inkludert tilgang til spesielle arrangementer, medlemsområder, og mer. Medlemskap koster <u>kr 250,-</u> i året.
                </p>
            </section>

            <section className='mb-12'>
                <form onSubmit={handleSubmit}>
                    <div className='mb-4'>
                        <label htmlFor='fullName' className='block text-dark-shades mb-2'>Fullt navn</label>
                        <input type='text' id='fullName' name='fullName' value={formData.fullName} onChange={handleInputChange} className='w-full md:w-1/2 p-2 border border-dark-shades rounded' required />
                    </div>
                    <div className='mb-4'>
                        <label htmlFor='phoneNumber' className='block text-dark-shades mb-2'>Telefon nummer</label>
                        <input type='text' id='phoneNumber' name='phoneNumber' value={formData.phoneNumber} onChange={handleInputChange} className='w-full md:w-1/2 p-2 border border-dark-shades rounded' required />
                    </div>
                    <div className='mb-4'>
                        <label htmlFor='emailAddress' className='block text-dark-shades mb-2'>Epost adresse</label>
                        <input type='email' id='emailAddress' name='emailAddress' value={formData.emailAddress} onChange={handleInputChange} className='w-full md:w-1/2 p-2 border border-dark-shades rounded' required />
                    </div>
                    <div className='mb-4'>
                        <label htmlFor='faxAddress' className='block text-dark-shades mb-2'>Faktura adresse</label>
                        <input type='text' id='faxAddress' name='faxAddress' value={formData.faxAddress} onChange={handleInputChange} className='w-full md:w-1/2 p-2 border border-dark-shades rounded' required />
                    </div>
                    <div className='mb-4'>
                        <button type='submit' className='bg-dark-accent text-light-shades py-2 px-6 rounded-full font-semibold text-lg hover:bg-dark-shades transition ease-in-out duration-300'>Bli medlem</button>
                    </div>
                </form>
            </section>
        </div>
    )
}

export default Membership