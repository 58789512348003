import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactMde from 'react-mde';
import * as Showdown from 'showdown';
import ReactMarkdown from 'react-markdown';
import 'react-mde/lib/styles/css/react-mde-all.css';

const Dashboard = () => {

    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [formData, setFormData] = useState({
        type: '',
        title: '',
        content: '',
        author: ''
    });
    const [members, setMembers] = useState([]);
    const [selectedTab, setSelectedTab] = useState('write');
    const converter = new Showdown.Converter({
        tables: true,
        simplifiedAutoLink: true,
        strikethrough: true,
        tasklists: true,
    });

    const imageRef = useRef();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append('type', formData.type);
        data.append('title', formData.title);
        data.append('content', formData.content);
        data.append('author', formData.author);
        data.append('image', imageRef.current.files[0]);

        const response = await fetch('https://server.pellenilsen.no/news', {
            method: 'POST',
            body: data
        });

        const result = await response.json();
        console.log('Response:', result);

        setFormData({
            type: '',
            title: '',
            content: '',
            author: '',
            imageRef: ''
        });
    };

    const handleMdeChange = (content) => {
        setFormData({
            ...formData,
            content: content,
        });
    };

    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthentification = async () => {
            const token = localStorage.getItem('token');
            const response = await fetch('https://server.pellenilsen.no/check-auth', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setIsAuthenticated(response.ok);
        };

        checkAuthentification();

        const fetchMembers = async () => {
            try {
                const response = await fetch('https://server.pellenilsen.no/members');
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                const data = await response.json();
                setMembers(data);
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
            }
        };

        fetchMembers();
    }, []);

    if (isAuthenticated === null) {
        return <div>Laster...</div>
    }
    
    if (!isAuthenticated) {
        navigate('/');
    }

    return (
        <div className='bg-light-shades p-6 md:p-12'>
            <form onSubmit={handleSubmit} className='p-6 bg-white rounded shadow-lg'>
                <div className='mb-4'>
                    <label htmlFor='type' className='block text-gray-700 mb-2'>Type</label>
                    <input type='text' id='type' name='type' value={formData.type} onChange={handleInputChange} className='w-full p-2 border rounded' required />
                </div>
                <div className='mb-4'>
                    <label htmlFor='image' className='block text-gray-700 mb-2'>Image</label>
                    <input type='file' id='image' name='image' ref={imageRef} className='w-full p-2 border rounded' required />
                </div>
                <div className='mb-4'>
                    <label htmlFor='title' className='block text-gray-700 mb-2'>Title</label>
                    <input type='text' id='title' name='title' value={formData.title} onChange={handleInputChange} className='w-full p-2 border rounded' required />
                </div>
                <div className='mb-4'>
                    <label htmlFor='content' className='block text-gray-700 mb-2'>Content</label>
                    <ReactMde
                        value={formData.content}
                        onChange={handleMdeChange}
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown) =>
                            Promise.resolve(converter.makeHtml(markdown))
                        }
                        childProps={{
                            writeButton: {
                                tabIndex: -1
                            }
                        }}
                    />
                </div>
                <div className='mb-4'>
                    <label htmlFor='author' className='block text-gray-700 mb-2'>Author</label>
                    <input type='text' id='author' name='author' value={formData.author} onChange={handleInputChange} className='w-full p-2 border rounded' required />
                </div>
                <button type='submit' className='bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700'>Submit</button>
            </form>

            <div className='mt-12'>
                <h2 className='text-2xl mb-4'>Medlemmer</h2>
                <table className='min-w-full bg-white'>
                    <thead>
                        <tr>
                            <th className='py-2 px-4 border-b border-gray-200 bg-gray-50'>Fullt navn</th>
                            <th className='py-2 px-4 border-b border-gray-200 bg-gray-50'>Telefon nummer</th>
                            <th className='py-2 px-4 border-b border-gray-200 bg-gray-50'>Epost adresse</th>
                            <th className='py-2 px-4 border-b border-gray-200 bg-gray-50'>Faktura adresse</th>
                        </tr>
                    </thead>
                    <tbody>
                        {members.map(member => (
                            <tr key={member.id}>
                                <td className='py-2 px-4 border-b border-gray-200'>{member.FullName}</td>
                                <td className='py-2 px-4 border-b border-gray-200'>{member.PhoneNumber}</td>
                                <td className='py-2 px-4 border-b border-gray-200'>{member.EmailAddress}</td>
                                <td className='py-2 px-4 border-b border-gray-200'>{member.FaxAddress}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Dashboard