import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Celebration from '../../assets/carousel/Celebration.png';
import Outside from '../../assets/carousel/Outside.png';
import Outside2 from '../../assets/carousel/Outside2.png';
import Portrait from '../../assets/carousel/Portrait.png';
import Workshop from '../../assets/carousel/Workshop.png';

const ImageCarousel = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        centerMode: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='py-12 px-6 bg-main-brand h-max space-x-4'>
            <Slider {...settings}>
                <div>
                    <img src={Celebration} alt='Barbie' className='w-auto h-[15rem] md:h-[35rem] rounded-lg mx-auto shadow-lg' />
                </div>
                <div>
                    <img src={Outside} alt='Barbie' className='w-auto h-[15rem] md:h-[35rem] rounded-lg mx-auto shadow-lg' />
                </div>
                <div>
                    <img src={Portrait} alt='Barbie' className='w-auto h-[15rem] md:h-[35rem] rounded-lg mx-auto shadow-lg' />
                </div>
                <div>
                    <img src={Outside2} alt='Barbie' className='w-auto h-[15rem] md:h-[35rem] rounded-lg mx-auto shadow-lg' />
                </div>
                <div>
                    <img src={Workshop} alt='Barbie' className='w-auto h-[15rem] md:h-[35rem] rounded-lg mx-auto shadow-lg' />
                </div>
            </Slider>
        </div>
    )
}

export default ImageCarousel