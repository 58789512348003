import React from 'react';
import Masonry from 'react-masonry-css';
import Celebration from '../assets/carousel/Celebration.png';
import Meeting from '../assets/carousel/Meeting.png';
import Outside from '../assets/carousel/Outside.png';
import Outside2 from '../assets/carousel/Outside2.png';
import Portrait from '../assets/carousel/Portrait.png';
import Workshop from '../assets/carousel/Workshop.png';
import Introduksjon from '../assets/introduksjon.jpg';
import HeroImage from '../assets/branok_1.JPG';

const photos = [
    Celebration, Portrait, Workshop, Introduksjon, Outside, Meeting, HeroImage, Outside2,
];

const Gallery = () => {
    const breakpointColumnsObj = {
        default: 5,
        1100: 4,
        700: 3,
        500: 2
    };

    return (
        <div className='bg-light-shades p-6 md:p-12'>
            <section className='mb-12'>
                <h1 className='text-4xl text-main-brand font-extrabold mb-4'>Galleri</h1>
            </section>

            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {photos.map((photo, index) => (
                    <img key={index} src={photo} alt={photo} className="w-full h-auto rounded-lg mb-4" />
                ))}
            </Masonry>
        </div>
    )
}

export default Gallery