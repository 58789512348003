import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const navigate = useNavigate();

    const [credentials, setCredentials] = useState({
        username: '',
        password: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCredentials({
            ...credentials,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const response = await fetch('https://server.pellenilsen.no/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });

        const data = await response.json();

        if (response.status === 200) {
            localStorage.setItem('token', data.token);
            console.log('Authentification successful:', data.message);
            navigate('/dashboard');
        } else {
            console.error('Authentification failed:', data.message);
        }
    };

    return (
        <div className='bg-light-shades flex items-center justify-center p-6 md:p-12'>
            <form onSubmit={handleSubmit} className='bg-white p-6 md:p-8 rounded-lg shadow-lg w-full max-w-md'>
                <h1 className='text-2xl text-main-brand font-extrabold mb-4'>Log inn</h1>
                <div className='mb-4'>
                    <label htmlFor='username' className='blolck text-dark-shades mb-2'>Brukernavn</label>
                    <input type='text' id='username' name='username' value={credentials.username} onChange={handleInputChange} className='w-full p-2 border border-dark-shades rounded' required />
                </div>
                <div className='mb-4'>
                    <label htmlFor='password' className='blolck text-dark-shades mb-2'>Password</label>
                    <input type='password' id='password' name='password' value={credentials.password} onChange={handleInputChange} className='w-full p-2 border border-dark-shades rounded' required />
                </div>
                <div className='flex justify-between items-center'>
                    <button type='submit' className='bg-dark-accent text-light-shades py-2 px-6 rounded-full font-semibold text-lg hover:bg-dark-shades transition ease-in-out duration-300'>
                        Log inn
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Login