import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';
import IntroImage from '../../assets/introduksjonBilde.jpg';
import HomePage1 from '../../assets/homePage1.png';

const Introduction = () => {
    return (
        <section className='bg-light-shades p-8'>
            <div className='container mx-auto'>
                <div className='flex flex-col md:flex-row items-center md:items-start py-12 md:py-24 px-6 md:px-12'>
                    <div className='w-full md:w-1/2 mb-8 md:mb-0'>
                        <img src={HomePage1} alt='BraNok Møteplass' className='md:w-2/3 w-full h-auto rounded-lg shadow-lg md:mx-0 mx-auto' />
                    </div>
                    <div className='w-full md:w-1/2 text-center md:text-left my-auto'>
                        <h2 className='text-3xl text-main-brand font-extrabold mb-4'>Velkommen til BraNok Møteplass</h2>
                        <p className='text-lg text-dark-shades mb-6'>
                            BraNok Møteplass er en fellesskapssentrisk møteplass som ligger på Trosvikstranda, Fredrikstad. Vi tilbyr et varmt og innbydene miljø for enkeltpersoner å koble av, dele erfaring, og skape fellesskap.
                        </p>
                        <Link to='/om' className='bg-dark-accent text-light-shades py-2 px-6 rounded-full font-semibold text-lg hover:bg-dark-shades transition ease-in-out duration-300 inline-flex peer-hover:ml-6 introduction-button-div-hover'>
                            <p>Les mer</p>
                            <AiOutlineArrowRight className='my-auto ml-4 introduction-arrow-move transition ease-in-out duration-300' size={20} />
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Introduction