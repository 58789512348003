import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import ReactMarkdown from 'react-markdown';

const PostDetails = () => {
    const { id } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            const response = await fetch(`https://server.pellenilsen.no/news/${id}`);
            const data = await response.json();
            setPost(data);
        };

        fetchPost();
    }, [id]);

    if (!post) {
        return <div>Laster...</div>
    }

    return (
        <div className='bg-light-shades p-6 md:p-12'>
            <Link to='/nyheter' className='flex'>
                <BiArrowBack size={30} className='mb-4' />
                <h1 className='text-2xl font-bold ml-4'>Tilbake</h1>
            </Link>
            <h1 className='text-4xl text-main-brand font-extrabold mb-4'>{post.Title}</h1>
            <img src={`https://server.pellenilsen.no${post.Image}`} alt={post.Title} className='w-1/2 h-auto rounded-lg mb-4' />
            <ReactMarkdown className='text-dark-shades mb-4 custom-markdown-text-field'>{post.Content}</ReactMarkdown>
            <div className='flex justify-between items-center'>
                <span className='text-light-accent text-sm'>{post.WrittenOn}</span>
                <span className='text-light-accent text-sm'>by {post.Author}</span>
            </div>
        </div>
    )
}

export default PostDetails