import React from 'react';

const Contact = () => {
    return (
        <div className='bg-light-shades p-6 md:p-12'>
            <section className='mb-12'>
                <h1 className='text-4xl text-main-brand font-extrabold mb-4'>Kontakt oss</h1>
                <p className='text-lg text-dark-shades mb-4'>
                    Vi ønsker deg velkommen til å kontakte oss for eventuelle henvendelser, forslag eller tilbakemeldinger. Slik kan du komme i kontakt med oss:
                </p>
            </section>

            <section className='mb-12'>
                <h2 className='text-3xl text-main-brand font-extrabold mb-4'>Kontakt informasjon</h2>
                <div className='text-lg text-dark-shades'>
                    <p><strong>Adresse:</strong> BraNok Møteplass, Trosvikstranda 9, Fredrikstad</p>
                    {/* <p><strong>Telefon:</strong> +47 450 92</p> */}
                    <p><strong>Epost:</strong> branokrebell@gmail.com</p>
                </div>
            </section>
        </div>
    )
}

export default Contact