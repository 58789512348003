import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const News = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            const response = await fetch('https://server.pellenilsen.no/news', { mode: 'cors' });
            const data = await response.json();
            setPosts(data);
        };

        fetchPosts();

        console.log(posts);
    }, []);

    return (
        <div className='bg-light-shades p-6 md:p-12'>
            <section className='mb-12'>
                <h1 className='text-4xl text-main-brand font-extrabold mb-4'>Nyheter</h1>
            </section>

            <section className='grid grid-cols-1 md:grid-cols-3 gap-12 p-6 md:p-12'>
                {posts.map(post => (
                    <Link to={`/nyhet/${post.id}`} key={post.id} className='bg-white p-6 rounded-lg shadow-lg'>
                        <p className='text-sm md:text-xl text-light-accent mb-2'>{post.type}</p>
                        <img src={`https://server.pellenilsen.no${post.Image}`} alt={post.Title} className='w-full h-auto rounded-lg mb-4' />
                        <h2 className='text-2xl text-main-brand font-extrabold mb-2'>{post.Title}</h2>
                        <p className='text-dark-shades mb-4'>{post.Content.slice(0, 25)}...</p>
                        <div className='flex justify-between items-center'>
                            <span className='text-light-accent text-sm'>{post.WrittenOn}</span>
                            <span className='text-light-accent text-sm'>by {post.Author}</span>
                        </div>
                    </Link>
                ))}
            </section>
        </div>
    )
}

export default News