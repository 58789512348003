import React from 'react';
import Celebration from '../assets/carousel/Celebration.png';
import Meeting from '../assets/carousel/Meeting.png';
import Outside from '../assets/carousel/Outside.png';
import Outside2 from '../assets/carousel/Outside2.png';
import Portrait from '../assets/carousel/Portrait.png';
import Workshop from '../assets/carousel/Workshop.png';

const events = [
    {
        'id': 1,
        'title' : 'Storslått åpning av BraNok Møteplass',
        'content': 'Vi er glade for å kunne kunngjøre åpningen av BraNok Møteplass, ditt nye favorittsted for fellesskap! BraNok Møteplass ligger i hjertet av byen og er designet for å være et fristed for sosial interaksjon, kreativitet og avslapning...',
        'date': '25. oktober 2023',
        'place': 'BraNok Møteplass, Fredrikstad',
        'author': 'Hege Hansen',
        'image': Celebration
    },
    {
        'id': 2,
        'title' : 'Utforsk naturens underverker',
        'content': 'Naturen har alltid hatt et spesielt sted i våre hjerter. Dens skjønnhet, mangfold og evne til å inspirere ærefrykt er virkelig uten sidestykke...',
        'date': '10. november 2023',
        'place': 'Diverse naturområder',
        'author': 'Pelle Nilsen',
        'image': Meeting
    },
    {
        'id': 3,
        'title' : 'Kunsten å Mindfulness Meditasjon',
        'content': 'I dagens fartsfylte verden er det viktig å finne øyeblikk av fred og ro for vårt velvære. Mindfulness meditasjon er en kraftig praksis som kan hjelpe deg med å redusere stress, forbedre fokus og dyrke en større følelse av selvbevissthet...',
        'date': '5. desember 2023',
        'place': 'Zen Meditation Center, Bergen',
        'author': 'Hege Hansen',
        'image': Outside
    },
    {
        'id': 4,
        'title' : 'De kulinariske herlighetene i Italia',
        'content': 'Italia er kjent for sin rike kulinariske arv, og det er ikke rart hvorfor. Fra de appetittvekkende pastarettene i Roma til den deilige sjømaten på Amalfikysten, tilbyr italiensk mat et fristende utvalg av smaker og teksturer...',
        'date': '15. januar 2024',
        'place': 'Italienske restauranter rundt om i landet',
        'author': 'Hege Hansen',
        'image': Outside2
    },
    {
        'id': 5,
        'title' : 'Fremtiden for romforskning',
        'content': 'Menneskehetens søken etter å utforske kosmos har nådd nye høyder de siste årene. Med fremskritt innen teknologi og internasjonalt samarbeid er vi på randen av en ny æra av romforskning...',
        'date': '20. februar 2024',
        'place': 'International Space Research Center, Houston',
        'author': 'Pelle Nilsen',
        'image': Portrait
    },
    {
        'id': 6,
        'title' : 'Bærekraftig livsstil for en bedre morgendag',
        'content': 'Viktigheten av bærekraftig livsstil har aldri vært tydeligere. Klimaendringer, ressursutarming og miljøødeleggelser er presserende globale utfordringer...',
        'date': '8. mars 2024',
        'place': 'Online webinar',
        'author': 'Hege Hansen',
        'image': Workshop
    },
    {
        'id': 7,
        'title' : 'Verden av Virtual Reality Gaming',
        'content': 'VR-spill (Virtual Reality) har revolusjonert måten vi opplever videospill på. Med oppslukende miljøer, realistiske simuleringer og interaktiv spilling, tilbyr VR-spill et nivå av innlevelse som en gang var utenkelig...',
        'date': '12. april 2024',
        'place': 'Virtual Reality Arcade, New York',
        'author': 'Pelle Nilsen',
        'image': Celebration
    }
];

const Events = () => {
    return (
        <div className='bg-light-shades p-6 md:p-12'>
            <section className='mb-12'>
                <h1 className='text-4xl text-main-brand font-extrabold mb-4'>Hendelser</h1>
            </section>

            <section className='grid grid-cols-1 md:grid-cols-3 gap-12 p-6 md:p-12'>
                {events.map(event => (
                    <div key={event.id} className='bg-white p-6 rounded-lg shadow-lg'>
                        <img src={event.image} alt={event.title} className='w-full h-auto rounded-lg mb-4' />
                        <h2 className='text-2xl text-main-brand font-extrabold'>{event.title}</h2>
                        <h3 className='text-sm text-light-accent mb-2'>{event.place}</h3>
                        <p className='text-dark-shades mb-4'>{event.content.slice(0, 25)}...</p>
                        <div className='flex justify-between items-center'>
                            <span className='text-light-accent text-sm'>{event.date}</span>
                            <span className='text-light-accent text-sm'>by {event.author}</span>
                        </div>
                    </div>
                ))}
            </section>
        </div>
    )
}

export default Events