import React from 'react';
import Hero from '../components/Home/Hero';
import Introduction from '../components/Home/Introduction';
import ImageCarousel from '../components/Home/ImageCarousel';
import Membership from '../components/Home/Membership';

const Homepage = () => {
    return (
        <div>
            <Hero />
            <Introduction />
            <ImageCarousel />
            <Membership />
        </div>
    )
}

export default Homepage