import React from 'react';
import ViTilbyr1 from '../assets/viTilbyr/viTilbyr1.png';
import ViTilbyr2 from '../assets/viTilbyr/viTilbyr2.png';
import ViTilbyr3 from '../assets/viTilbyr/viTilbyr3.png';
import ViTilbyr4 from '../assets/viTilbyr/viTilbyr4.png';
import { Link } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';

const HealthOffice = () => {
    return (
        <div className='bg-light-shades p-6 md:p-12'>
            <section className='flex flex-col md:flex-row mb-12'>
                <div className='w-full md:w-1/2 p-4 my-auto'>
                    <h1 className='text-4xl text-main-brand font-extrabold mb-4'>Rådgiving og veiledning</h1>
                    <p className='text-lg text-dark-shades mb-4'>
                        Vi tilbyr veiledning og rådgiving til alle LHBT+ innbyggere. Her kan du få prate om det du lurer på om skeiv helse, få informasjon om andre hjelpeinstanser, og få ledsager til hjelpeinstanser ved behov.
                    </p>
                    <p className='text-lg text-dark-shades mb-4'>
                        Vi deler ut brosjyrer om skeiv helse, og kunnskap om legning og kjønnsidentitet.
                    </p>
                    <p className='text-lg text-dark-shades mb-4'>
                        Hos oss er også pårørende hjertelig velkommen. Mange har spørsmål knyttet til skeiv tematikk og helse, og vi kan være samtalepartner hvor vi informerer om denne tematikken. Vi kan bidra med informasjon til skole eller bedrift og være en ressurs i ulike situasjoner som krever det.
                    </p>
                </div>
                <div className='w-full md:w-1/3 p-4 mx-auto'>
                    <img src={ViTilbyr1} alt='Image 1' className='w-full h-auto rounded-lg mb-4' />
                </div>
            </section>

            <section className='flex flex-col md:flex-row-reverse mb-12'>
                <div className='w-full md:w-1/2 p-4 my-auto'>
                    <h1 className='text-4xl text-main-brand font-extrabold mb-4'>Fagdager, kurs og kompetanseheving</h1>
                    <p className='text-lg text-dark-shades mb-4'>
                        For å kunne skape tillit og trygghet for skeive, så handler det om å ha kunnskap. Det handler om å ha kunnskap nok til å våge å stille spørsmål, og til å være et sted som formidler trygghet for denne målgruppen.
                    </p>
                    <p className='text-lg text-dark-shades mb-4'>
                        Vi holder fagdager på sykehjem, skole, bedrifter, etc. Vi tilrettelegger kurset slik at det best skal passe deres virksomhet.
                    </p>
                    <Link to='/kontakt' className='bg-dark-accent text-light-shades py-2 px-6 rounded-full font-semibold text-lg hover:bg-dark-shades transition ease-in-out duration-300 inline-flex peer-hover:ml-6 introduction-button-div-hover'>
                        <p>Kontakt oss for informasjon of pris</p>
                        <AiOutlineArrowRight className='my-auto ml-4 introduction-arrow-move transition ease-in-out duration-300' size={20} />
                    </Link>
                </div>
                <div className='w-full md:w-1/3 p-4 mx-auto'>
                    <img src={ViTilbyr2} alt='Image 2' className='w-full h-auto rounded-lg mb-4' />
                </div>
            </section>

            <section className='flex flex-col md:flex-row mb-12'>
                <div className='w-full md:w-1/2 p-4 my-auto'>
                    <h1 className='text-4xl text-main-brand font-extrabold mb-4'>Hvem er vi</h1>
                    <p className='text-lg text-dark-shades mb-4'>
                        Vi er et team bestående av LHBT+ helsepersonell, og frivillige som bistår med vår kompetanse, kunnskap og erfaring. Vi samarbeider med lavterskeltilbud i kommunen og på tvers vil vi gjøre det vi kan for at du skal føle deg ivaretatt hos oss.
                    </p>
                </div>
                <div className='w-full md:w-1/3 p-4 mx-auto'>
                    <img src={ViTilbyr3} alt='Image 3' className='w-full h-auto rounded-lg mb-4' />
                </div>
            </section>

            <section className='flex flex-col md:flex-row-reverse mb-12'>
                <div className='w-full md:w-1/2 p-4 my-auto'>
                    <h1 className='text-4xl text-main-brand font-extrabold mb-4'>Hva står vi for</h1>
                    <p className='text-lg text-dark-shades mb-4'>
                        På BraNok fremmer vi en kultur av aksept og lik behandling. Vårt fundament er bygget på prinsippene om empati, konfidensialitet, og omsorg med kompetanse, sikret gjennom en forståelse av de spesifikke utfordringene som LHBT+ samfunnet står overfor.
                    </p>
                    <p className='text-lg text-dark-shades mb-4'>
                        Vi ønsker likeverdige helsetjenester for alle.
                    </p>
                </div>
                <div className='w-full md:w-1/3 p-4 mx-auto'>
                    <img src={ViTilbyr4} alt='Image 4' className='w-full h-auto rounded-lg mb-4' />
                </div>
            </section>
        </div>
    )
}

export default HealthOffice