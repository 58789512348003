import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import HealthOffice from './pages/HealthOffice';
import PostDetails from './pages/PostDetails';
import Membership from './pages/Membership';
import Dashboard from './pages/Dashboard';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Homepage from './pages/Homepage';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import Events from './pages/Events';
import Login from './pages/Login';
import About from './pages/About';
import News from './pages/News';

const navLinks = [
    { id: 1, to: '/om', name: 'Om oss' },
    { id: 2, to: '/medlemskap', name: 'Medlemskap' },
    { id: 3, to: '/nyheter', name: 'Nyheter' },
    { id: 4, to: '/kontakt', name: 'Kontakt oss' },
    { id: 5, to: '/helsekontor', name: 'Vi tilbyr' },
];

function App() {
    return (
        <Router>
            <Navbar navLinks={navLinks} />
            <ScrollToTop />
            <Routes>
                <Route path='/' exact element={<Homepage />} />
                <Route path='/medlemskap' element={<Membership />} />
                <Route path='/kontakt' element={<Contact />} />
                <Route path='/galleri' element={<Gallery />} />
                <Route path='/hendelser' element={<Events />} />
                <Route path='/om' element={<About />} />
                <Route path='/nyheter' element={<News />} />
                <Route path='/helsekontor' element={<HealthOffice />} />
                <Route path='/admin12' element={<Login />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/nyhet/:id' element={<PostDetails />} />
            </Routes>
            <Footer navLinks={navLinks} />
        </Router>
    );
}

export default App;
