import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({ navLinks }) => {

    return (
        <footer className='bg-dark-shades text-light-shades py-12 px-6 md:px-12'>
            <div className='container mx-auto'>
                <div className='flex flex-col md:flex-row justify-between items-center'>
                    <div className='mb-8 md:mb-0'>
                        <Link to='/' className='text-2xl font-bold text-main-brand'>BraNok Møteplass</Link>
                    </div>
                    <nav className='mb-8 md:mb-0'>
                        <ul className='flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 text-center'>
                            {navLinks.map(link => (
                                <li key={link.id}>
                                    <Link to={link.to} className='hover:text-light-accent'>{link.name}</Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <div className='text-center md:text-right'>
                        <p className='text-main-brand text-2xl'>BraNok Møteplass</p>
                        <p>Trosvikstranda 9, Fredrikstad</p>
                        <p>Email: branokrebell@gmail.com</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer