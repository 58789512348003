import React from 'react';
import HeroImage from '../../assets/branok_1.JPG';

const Hero = () => {
    return (
        <div className='relative bg-cover bg-center h-[600px]' style={{ backgroundImage: 'url(' + HeroImage + ')' }}>
            <div className='absolute inset-0 bg-black opacity-50'></div>
            <div className='relative z-10 flex flex-col items-center justify-center h-full text-center'>
                <h1 className='text-6xl text-light-shades font-extrabold mb-4'>BraNok Møteplass</h1>
                <p className='text-2xl text-light-accent font-semibold'>Trosvikstranda 9</p>
            </div>
        </div>
    )
}

export default Hero