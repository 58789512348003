import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiFillCloseCircle } from 'react-icons/ai';

const Navbar = ({ navLinks }) => {
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    const MobileNav = () => {
        return (
            <div className='inline-flex justify-between'>
                <div className={`fixed top-0 left-0 w-full h-full bg-main-brand z-50 ${isMobileNavOpen ? 'flex' : 'hidden'} flex-col items-center justify-center space-y-6`}>
                    <div className='flex flex-col items-center space-y-6'>
                        {navLinks.map((link) => (
                            <Link key={link.id} to={link.to} className='text-dark-shades hover:text-dark-accent transition-all text-2xl' onClick={() => setIsMobileNavOpen(false)}>
                                {link.name}
                            </Link>
                        ))}
                        <button type='button' className='absolute bottom-12 bg-transparent border-none' onClick={() => setIsMobileNavOpen(false)}>
                            <AiFillCloseCircle size={50} className='text-dark-shades' />
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    const DesktopNav = () => {
        return (
            <div className='hidden sm:flex justify-between items-center my-auto'>
                <div className='font-bold text-3xl text-dark-shades'>
                    <Link to='/'>BraNok Møteplass</Link>
                </div>
                <ul className='flex space-x-4'>
                    {navLinks.map((link) => (
                        <li key={link.id}>
                            <Link to={link.to} className='text-dark-shades hover:text-dark-accent transition-all text-xl'>
                                {link.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    return (
        <nav className='bg-main-brand p-4'>
            <div className='container mx-auto p-4 relative'>
                <div className='flex justify-between items-center sm:hidden'>
                    <div className='font-bold text-3xl text-dark-shades'>
                        <Link to='/'>BraNok Møteplass</Link>
                    </div>
                    <button type='button' className='text-dark-shades text-3xl' onClick={() => setIsMobileNavOpen(true)}>
                        ☰
                    </button>
                </div>
                <DesktopNav />
                <MobileNav />
            </div>
        </nav>
    )
}

export default Navbar