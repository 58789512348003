import React from 'react';
import omoss from '../assets/about/omoss.png';
import hvagjorvi from '../assets/about/hvagjorvi.png';
// import hvemervi from '../assets/about/hvemervi.png';
import hvemervi from '../assets/viTilbyr/viTilbyr1.png';
import varekjerneverdier from '../assets/about/varekjerneverdier.png';
import hvorforbordukomme from '../assets/about/hvorforbordukomme.png';

const About = () => {
    return (
        <div className='bg-light-shades p-6 md:p-12'>
            <section className='flex flex-col md:flex-row mb-12'>
                <div className='w-full md:w-1/2 p-4 my-auto'>
                    <h1 className='text-4xl text-main-brand font-extrabold mb-4'>Om oss</h1>
                    <p className='text-lg text-dark-shades mb-4'>
                        BraNok Møteplass er en rusfri møteplass for alle LHBTIQ+ personer. Målet vårt er å skape en trygg arena for skeive i alle aldre, hvor du kan finne likepersoner i et inkluderende miljø. Vi holder til i fine lokaler i sentrum i Fredrikstad.
                    </p>
                </div>
                <div className='w-full md:w-1/3 p-4 mx-auto'>
                    <img src={omoss} alt='Image 1' className='w-full h-auto rounded-lg mb-4' />
                </div>
            </section>

            <section className='flex flex-col md:flex-row-reverse mb-12'>
                <div className='w-full md:w-1/2 p-4 my-auto'>
                    <h1 className='text-4xl text-main-brand font-extrabold mb-4'>Hva gjør vi</h1>
                    <p className='text-lg text-dark-shades mb-4'>
                        Hver onsdag har vi sosial kveld for alle aldre. Noen ganger temakvelder som quiz, håndarbeidskveld eller spillkvelder. I tillegg har vi egne ungdomskvelder, kvelder for regnbuefamilier og latebloomer ladies. Følg med på nyheter for kommende arrangementer.
                    </p>
                    <p className='text-lg text-dark-shades mb-4'>
                        Vi jobber også med kompetanseheving om skeiv helse og livskvalitet, og holder gjerne foredrag/fagdag på din bedrift.
                    </p>
                </div>
                <div className='w-full md:w-1/3 p-4 mx-auto'>
                    <img src={hvagjorvi} alt='Image 2' className='w-full h-auto rounded-lg mb-4' />
                </div>
            </section>

            <section className='flex flex-col md:flex-row mb-12'>
                <div className='w-full md:w-1/2 p-4 my-auto'>
                    <h1 className='text-4xl text-main-brand font-extrabold mb-4'>Hvem er vi</h1>
                    <h2 className='text-2xl text-dark-accent font-extrabold mb-4'>Stiftere</h2>
                    <p className='text-lg text-dark-shades mb-2'>
                        <span className='text-2xl text-dark-accent font-bold'>Hege (hun/henne)</span>, 45 år, utdannet hjelpepleier med videreutdanning innenfor kreftomsorg og praktisk ledelse. Samfunnsengasjert og et stort hjerte for at mennesker skal få være seg selv.
                    </p>
                    <div className="fun-fact mb-10 w-full bg-yellow-50 p-4 rounded-xl border-dashed border-yellow-500 border-4">
                        <p className='text-2xl text-yellow-500 font-bold'>Fun Fact:</p>
                        <p className='text-xl text-light-accent'>Trives med en skrivebok i hånden.</p>
                    </div>
                    <p className='text-lg text-dark-shades mb-2'>
                        <span className='text-2xl text-dark-accent font-bold'>Marthe (hun/henne)</span>, 33 år, utdannet ambulansearbeider med videreutdanning. Brenner for å forebygge uhelse og dårlig livskvalitet. Ønsker et mer kunnskapsrikt samfunn om alle legninger og kjønnsidentiteter.
                    </p>
                    <div className="fun-fact mb-4 w-full bg-yellow-50 p-4 rounded-xl border-dashed border-yellow-500 border-4">
                        <p className='text-2xl text-yellow-500 font-bold'>Fun Fact:</p>
                        <p className='text-xl text-light-accent'>Elsker musikk og benytter sjansen til å være DJ når anledningen byr seg.</p>
                    </div>
                </div>
                <div className='w-full md:w-1/3 p-4 mx-auto'>
                    <img src={hvemervi} alt='Image 3' className='w-full h-auto rounded-lg mb-4' />
                </div>
            </section>

            <section className='flex flex-col md:flex-row-reverse mb-12'>
                <div className='w-full md:w-1/2 p-4 my-auto'>
                    <h1 className='text-4xl text-main-brand font-extrabold mb-4'>Våre kjerneverdier</h1>
                    <p className='text-lg text-dark-shades mb-4'>
                        På BraNok møteplass tror vi på å fremme fellesskap og inkludering. Vi er et sted med høy takhøyde. Vi har taushetsplikt ovenfor hverandre. Trygghet for alle som kommer.
                    </p>
                </div>
                <div className='w-full md:w-1/3 p-4 mx-auto'>
                    <img src={varekjerneverdier} alt='Image 4' className='w-full h-auto rounded-lg mb-4' />
                </div>
            </section>

            <section className='flex flex-col md:flex-row mb-12'>
                <div className='w-full md:w-1/2 p-4 my-auto'>
                    <h1 className='text-4xl text-main-brand font-extrabold mb-4'>Hvorfor du bør komme</h1>
                    <p className='text-lg text-dark-shades mb-4'>
                        Å engasjere seg i BraNok Møteplass gir mange fordeler. Enten du ønsker et sted å være, skape et sosialt nettverk, finne likepersoner, lære om mangfold, eller finne tilhørighet. Hos oss vil du bidra til et levende og mangfoldig samfunn.
                    </p>
                </div>
                <div className='w-full md:w-1/3 p-4 mx-auto'>
                    <img src={hvorforbordukomme} alt='Image 5' className='w-full h-auto rounded-lg mb-4' />
                </div>
            </section>
        </div>
    )
}

export default About