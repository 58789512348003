import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';

const Membership = () => {
    return (
        <div className='bg-light-shades py-12 px-6 md:px-12'>
            <div className='text-center md:text-left'>
                <h2 className='text-3xl text-main-brand font-extrabold mb-4'>Bli et medlem</h2>
                <p className='text-lg text-dark-shades mb-6'>
                    Ved å bli et medlem på BraNok Møteplass støtter du ikke bare fellesskapet vårt, men får også ekslusive fordeler. Medlemmer mottar invitasjoner til spesielle arrangementer, tilgang til områder kun for medlemmer, og mer. Bli med og bli en del av noe større.
                </p>
                <Link to='/medlemskap' className='bg-dark-accent text-light-shades py-2 px-6 rounded-full font-semibold text-lg hover:bg-dark-shades transition ease-in-out duration-300 inline-flex peer-hover:ml-6 introduction-button-div-hover'>
                    <p>Bli medlem nå</p>
                    <AiOutlineArrowRight className='my-auto ml-4 introduction-arrow-move transition ease-in-out duration-300' size={20} />
                </Link>
            </div>
        </div>
    )
}

export default Membership